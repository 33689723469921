import {TPLPaginated} from "./tpl_paginated";

class OperatorFinanceChangeWidget extends TPLPaginated {

    constructor() {
        super('operator_finance_change');

        window.addEventListener('AutoCompleteSelect', async (e) => {
            if(e.detail.tbl !== 'opr') {
                return;
            }

            app.DOM.content.find(`#${this.GRID_ID} #opr_finance_change_name`).css('background-color', '#fdebc8');
            this.CURRENT_PAGE = 0;
            this.EXTRA_DATA.operator_id = e.detail.data.value;
            await this.update();
        });

        window.addEventListener('AutoCompleteClear', async (_e) => {
            this.CURRENT_PAGE = 0;
            this.EXTRA_DATA = {};
            await this.update();
            app.DOM.content.find(`#${this.GRID_ID} #opr_finance_change_name`).css('background-color', 'field');
        });
    }
}

$(() => {
    if(app && app.DASHBOARD_CORE) {
        new OperatorFinanceChangeWidget();
    } else {
        console.warn('DashboardCore not initalized.');
    }
});