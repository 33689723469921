import { TPLBase } from "./tpl_base";
import {AjaxPromise} from "../../shared/ajax_utilities";
import {manifest} from "../../shared/common";

export class TPLPaginated extends TPLBase {
    CURRENT_PAGE = 0;
    LOADING = true;
    EXTRA_DATA = {};

    constructor(gridId) {
        super(gridId);
        app.DOM.content.find(`#${this.GRID_ID} .scrollable`).unbind('scroll').on('scroll', async (e) => {
            if(this.LOADING) {
                return;
            }

            const el = $(e.currentTarget);
            if(el.scrollTop() + el.innerHeight() >= el[0].scrollHeight) {
                await this.update();
            }
        });
    }

    async setup() {
        if(!app.OPTIONS.gridstack_allowed || !app.OPTIONS.gridstack_allowed[this.GRID_ID]) {
            console.warn(`Unable to find gridstack options for ${this.GRID_ID} and is unable to setup tpl`);
            return;
        }
        const opts = app.OPTIONS.gridstack_allowed[this.GRID_ID];
        if(!opts.ajax_tpl) {
            console.warn(`Unable to setup ${this.GRID_ID} tpl`);
            return;
        }

        try {
            let data = {
                dashboard: app.DASHBOARD_CORE.DASHBOARD,
                allowed: app.DASHBOARD_CORE.getDataGridstack(),
                page: this.CURRENT_PAGE,
            };

            if(this.EXTRA_DATA) {
                data = { ...data, ...this.EXTRA_DATA };
            }

            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: data
            });

            if(this.CURRENT_PAGE === 0) {
                app.DASHBOARD_CORE.paintTpl(app.OPTIONS.gridstack_allowed[this.GRID_ID].ajax_tpl, res, this.GRID_ID);
                this.createLoading();
            } else {
                $(`#${this.GRID_ID} .scrollable table .loading`).before(res);
            }

            this.CURRENT_PAGE++;
        } catch(err) {
            console.error(`Unable to setup ${this.GRID_ID}`, err);
        } finally {
            this.LOADING = false;
        }
    }

    async update() {
        try {
            let data = {
                dashboard: app.DASHBOARD_CORE.DASHBOARD,
                allowed: app.DASHBOARD_CORE.getDataGridstack(),
                page: this.CURRENT_PAGE,
            };

            if(this.EXTRA_DATA) {
                data = { ...data, ...this.EXTRA_DATA };
            }

            const opts = app.OPTIONS.gridstack_allowed[this.GRID_ID];
            this.LOADING = true;
            let loading = $(`#${this.GRID_ID} .scrollable table .loading`);
            if(!loading || loading.length === 0) {
                this.createLoading();
                loading = $(`#${this.GRID_ID} .scrollable table .loading`);
            }
            loading.css('display', 'table-row');
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX_TPL}${opts.ajax_tpl}`,
                method: 'POST',
                data: data
            });
            if(this.CURRENT_PAGE === 0) {
                app.DASHBOARD_CORE.paintTpl(app.OPTIONS.gridstack_allowed[this.GRID_ID].ajax_tpl, res, this.GRID_ID);
            } else {
                if(loading && loading.length > 0) {
                    loading.before(res);
                } else {
                    $(`#${this.GRID_ID} .scrollable tbody`).append(res);
                }
            }

            app.DASHBOARD_CORE.populateAdditionalRows();
            this.CURRENT_PAGE++;
        } catch(err) {
            console.log(err);
        } finally {
            this.LOADING = false;
            $(`#${this.GRID_ID} .scrollable table .loading`).css('display', 'none');
        }
    }

    createLoading() {
        app.DOM.content.find(`#${this.GRID_ID} .scrollable tbody`).append(`
            <tr class="loading" style="display:none;">
                <td colspan="${$('#'+this.GRID_ID + ' thead th').length}" style="height: 50px;" class="ac">
                    <img src="${manifest('/img/loading-black.svg')}" width="30">
                </td>
            </tr>
        `);
    }
}